import React from "react";

import { Box, CircularProgress, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import Logo from "src/assets/FairmintLogo.svg";

import styles from "./styles.module.less";

const style = theme => ({
  margin   : "0px",
  position : "fixed",
  top      : "calc(50% - 24px)",
  left     : "calc(50% - 24px)",
  color    : theme.palette.action.active,
});

const View = ({ text }) => {
  const theme = useTheme();

  return (
    <div className={styles.loadingOverlayDiv}>
      {!text && (
        <div className={styles.loadingCenterWrapper}>
          <CircularProgress style={style(theme)} />
        </div>
      )}
      {text && (
        <div className={styles.loadingCenterWithText}>
          <CircularProgress style={style(theme)} />
          <span className="text">{text}</span>
        </div>
      )}
    </div>
  );
};

function GradientCircularProgress() {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
    </>
  );
}

export function FairmintLogoLoadingSpinner() {
  return (
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={Logo} alt="Fairmint logo" style={{ height: "3rem", marginBottom: "1rem" }} />
        <GradientCircularProgress />
      </Box>
    </Box>
  );
}

View.propTypes = {
  text: PropTypes.string
};

export default View;
