import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  privyLogin     : ["email"],
  initialLoading : true,
  currentDialog  : {
    name  : null,
    props : null,
  }
};

const slice = createSlice({
  name     : "global",
  initialState,
  reducers : {
    setPrivyLogin         : (state, action) => ({ ...state, privyLogin: action.payload }),
    setCurrentDialog      : (state, action) => ({ ...state, currentDialog: action.payload }),
    disableInitialLoading : state => ({ ...state, initialLoading: false })
  }
});

export const { setPrivyLogin, setCurrentDialog, disableInitialLoading } = slice.actions;

export default slice.reducer;
