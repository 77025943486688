import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { disableInitialLoading } from "src/redux/slices/global";

const useGlobalLoading = () => {
  const loading = useSelector(state => state.global?.initialLoading);
  const dispatch = useDispatch();

  const disableInitialLoadingScreen = useCallback(() => {
    dispatch(disableInitialLoading());
  }, [dispatch]);

  return { loading, disableInitialLoadingScreen  };
};

export default useGlobalLoading;
